<template>
  <div class="announcements-list">
    <div class="d-flex align-center flex-sm-nowrap flex-wrap">
      <div class="text-h5 font-weight-bold">Announcements</div>
      <v-spacer></v-spacer>
      <v-btn to="/announcements-new" depressed width="136" large class="primary mt-sm-0 mt-2">Create new</v-btn>
    </div>
    <div class="mt-4">
      <v-card tile flat color="transparent" v-if="!data.result" class="d-flex justify-center align-center" :height="`calc(100vh - 240px)`">
        <div>
          <v-img height="150" contain src="@/assets/img/bad.svg"></v-img>
          <div class="text-center text--text mt-4">Sorry, but nothing found</div>
        </div>
      </v-card>
      <div v-else>
        <v-data-table
          hide-default-footer
          fixed-header
          :headers="headers"
          :items="data.result"
          item-key="id"
          :items-per-page="-1"
          :height="`calc(100vh - 240px)`"
          class="d-sm-block d-none accent"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.id" class="link" @click="openLink(item.id)">
                <td>{{ item.message_title }}</td>
                <td class="text-capitalize">{{ item.sending_type }}</td>
                <td>
                  {{
                    item.scheduled_at
                      ? new Date(item.scheduled_at).toLocaleString('en-GB', {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                        })
                      : new Date(item.submitted_at).toLocaleString('en-GB', {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                        })
                  }}
                </td>
                <td>{{ item.messages_sent }}</td>
                <td>{{ item.messages_opened }}</td>
                <td>{{ item.conversion_rate }}%</td>
                <td class="text-capitalize">
                  {{ item.status }}
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn small v-if="item.status == 'scheduled'" v-bind="attrs" v-on="on" icon>
                        <v-icon color="gray">mdi-dots-vertical</v-icon>
                      </v-btn>
                      <v-btn disabled icon small v-else></v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="cancel(item.id)" class="error--text link">Cancel</v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </tbody>
          </template>
          <template v-slot:footer>
            <v-divider></v-divider>
            <div class="mt-2 pb-2 px-3 d-flex align-center">
              <div class="text--text">Total {{ data.total }}</div>
              <v-spacer></v-spacer>
              <v-btn outlined small min-width="28" color="text" class="mr-1 px-0" @click="formerPage">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <span class="mx-4 text--text">{{ page }} of {{ data.pages }}</span>
              <v-btn outlined small min-width="28" color="text" class="ml-1 px-0" @click="nextPage">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <v-divider></v-divider>
          </template>
        </v-data-table>
        <div class="d-block d-sm-none">
          <div v-for="item in data.result" :key="item.id" @click="openLink(item.id)" class="px-4 pt-2">
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Notification</div>
              <div class="font-weight-medium f18">{{ item.message_title }}</div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Type</div>
              <div class="font-weight-medium f18 text-capitalize">{{ item.sending_type }}</div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Submitted/Scheduled</div>
              <div class="font-weight-medium f18">
                {{
                  item.scheduled_at
                    ? new Date(item.scheduled_at).toLocaleString('en-GB', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                      })
                    : new Date(item.submitted_at).toLocaleString('en-GB', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                      })
                }}
              </div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Sent</div>
              <div class="font-weight-medium f18">{{ item.messages_sent }}</div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Opened</div>
              <div class="font-weight-medium f18">{{ item.messages_opened }}</div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Rate</div>
              <div class="font-weight-medium f18">{{ item.conversion_rate }}%</div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Status</div>
              <div class="font-weight-medium f18 text-capitalize">{{ item.status }}</div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="mt-2 pb-2 px-3 d-flex align-center justify-center flex-wrap">
            <div class="text--text">Total {{ data.total }}</div>
            <v-spacer></v-spacer>
            <div class="ms-2">
              <v-btn outlined small min-width="28" color="text" class="mr-1 px-0" @click="formerPage">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <span class="mx-4 text--text">{{ page }} of {{ data.pages }}</span>
              <v-btn outlined small min-width="28" color="text" class="ml-1 px-0" @click="nextPage">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      page: Number(this.$route.query.page) || 1,
      headers: [
        { text: 'Notification', value: 'message_title', sortable: false },
        { text: 'Type', value: 'sending_type', sortable: false },
        { text: 'Submitted/Scheduled', value: 'submitted_at', sortable: false },
        { text: 'Sent', value: 'messages_sent', sortable: false },
        { text: 'Opened', value: 'messages_opened', sortable: false },
        { text: 'Rate', value: 'conversion_rate', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: '', value: 'none', sortable: false },
      ],
      error: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    notifi(btn) {
      if (btn == 'search400') {
        this.$notify({
          title: 'Warning',
          message: 'This field must be at least 3 characters, maximum 10',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == '500') {
        this.$notify({
          title: 'Something went wrong!',
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    async cancel(id) {
      await this.$store.dispatch('cancelAnnouncementsItem', id);
    },
    openLink(id) {
      this.$router.push(`/announcements/${id}`);
    },
    nextPage() {
      if (this.page != this.data.pages) {
        this.page += 1;
        this.getData();
      }
    },
    formerPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.getData();
      }
    },
    async getData() {
      await this.$store
        .dispatch('getAnnouncementsList', this.page)
        .then(() => {
          this.nothing = false;
        })
        .catch((e) => {
          this.getDataError(e);
        });
    },
    getDataError(e) {
      this.statusError = e.response.status;
      if (e.response.data.error[0] === 'page__out_of_bounds') {
        this.page = 1;
        this.getData();
      } else if (this.statusError >= 400 && this.statusError < 500) {
        this.notifi('search400');
      } else if (this.statusError >= 500) {
        this.notifi('500');
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters.announcementsList;
    },
  },
  destroyed() {
    this.$store.dispatch('setAnnouncementsList');
    this.$store.dispatch('setAnnouncementsItem');
  },
};
</script>

<style lang="scss">
.announcements-list {
  table tbody tr:hover td:first-of-type {
    color: var(--v-primary-base) !important;
  }
}
</style>
